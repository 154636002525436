<template>
<ul class="pagination pagination-outline">
    <li class="page-item next m-1" :class="[disablePreviousBtn ? 'disabled' : '']">
        <button class="page-link" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Primero" @click="goToFirstPage">
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotune/arrows/arr022.svg" />
            </span>
        </button>
    </li>
    <li class="page-item previous m-1" :class="[disablePreviousBtn ? 'disabled' : '']">
        <button class="page-link" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Anterior" @click="goToPreviousPage">
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotune/arrows/arr079.svg" />
            </span>
        </button>
    </li>
    <li 
        v-for="page in renderPages" 
        v-bind:key="page"
        class="page-item m-1"
        :class="{ active: page == currentSelectedPage}"
    >
        <button class="page-link" @click="handleClickPage(page)">{{ page }}</button>
    </li>
    <li class="page-item next m-1" :class="[disableNextBtn ? 'disabled' : '']">
        <button class="page-link" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Siguiente" @click="goToNextPage">
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotune/arrows/arr080.svg" />
            </span>
        </button>
    </li>
    <li class="page-item next m-1" :class="[disableNextBtn ? 'disabled' : '']">
        <button class="page-link" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Último" @click="goToLastPage">
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotune/arrows/arr023.svg" />
            </span>
        </button>
    </li>
</ul>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount, toRefs, watch } from "vue";

export default defineComponent({
    name: "app-paginator",
    props: {
        numItems: Number,
        itemsPerPage: Number,
        selectedPage: {
            type: Number,
            required: false,
            default: () => 1
        }
    },
    components: {},
    setup(props, context){
        const { numItems, itemsPerPage, selectedPage } = toRefs(props);
        let numPages = ref(0);
        let arrayPages = [];
        let currentPage = ref(1);
        let numDisplacement = 1;
        let lastPages = [];
        let min = 0;
        let max = 0;
        
        /**
         * Calcula el valor de las paginas
         */
        const calculateArrayPages = () => {
            lastPages = [];
            arrayPages = [];

            numPages.value = Math.ceil(numItems.value/itemsPerPage.value);// el modulo no me da las paginas correctas cuando es 11 % 10;
            
            let iterator=1;
            while(iterator <= numPages.value){
                if(!arrayPages.some(p => p == iterator)) {
                    arrayPages.push(iterator);
                }
                iterator++;
            }
        }
        currentPage.value = selectedPage.value;
        //calculamos el numero de paginas cada vez que inicia el componente 
        //y no solo cuando se cambien los valores de "itemsPerPage" y "numItems"
        calculateArrayPages();

        watch(itemsPerPage, (value) => {
            calculateArrayPages();
        });

        watch(numItems, (value) => {
           calculateArrayPages();
        });

        watch(selectedPage, (value) => {
            currentPage.value = value;
        });

        const handleClickPage = (page) => {
            currentPage.value = page;
            context.emit('handleClickPage', currentPage.value);
        }

        const goToFirstPage = () => {
            handleClickPage(1);
        }

        const goToPreviousPage = () => {
            handleClickPage(currentPage.value - 1);
        }

        const goToNextPage = () => {
            handleClickPage(currentPage.value + 1);
        }

        const goToLastPage = () => {
            handleClickPage(numPages.value);
        }

        const disablePreviousBtn = computed(() => {
            return currentPage.value == 1;
        });

        const disableNextBtn = computed(() => {
            return currentPage.value == renderPages.value[renderPages.value.length - 1];
        });

        const renderPages = computed(() => {
            if(numPages.value > itemsPerPage.value) {
                if(currentPage.value == 1) {
                    min = currentPage.value;
                    max = itemsPerPage.value;
                } else if(currentPage.value == numPages.value) {
                    min = numPages.value - (itemsPerPage.value - numDisplacement);
                    max = numPages.value;
                } else {
                    let minP = Math.min(...lastPages);
                    let maxP = Math.max(...lastPages);

                    if(currentPage.value == minP && currentPage.value != 1) {
                        min -= numDisplacement;
                        max -= numDisplacement;
                    }

                    if(currentPage.value == maxP) {
                        min += numDisplacement;
                        max += numDisplacement   
                    }
                }

                lastPages = arrayPages
                    .filter(item => item >= min)
                    .filter(item => item <= max)
                    .map(page => page);
                return lastPages;
                
            } else {
                lastPages = [...arrayPages];
                return arrayPages;
            }
            
        });

        const currentSelectedPage = computed(() => {
            return currentPage.value;
        });

        return {
            arrayPages, 
            currentPage, 
            renderPages,
            currentSelectedPage,
            disablePreviousBtn,
            disableNextBtn,
            handleClickPage,
            goToFirstPage,
            goToPreviousPage,
            goToNextPage,
            goToLastPage
        }
    }
});
</script>