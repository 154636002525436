<template>
    <div class="table-responsive">
        <table 
            id="tblTypeFishing" 
            class="table table-hover align-middle table-row-dashed fs-6 gy-5"
            :class="{'table-hover': activeHover}"
        >
            <thead>
            <tr class="ttext-start text-gray-400 fw-bold fs-7 text-uppercase gs-0" role="row">
                
                <th v-if="requireSelectMultiple" class="w-25px">
                    <div  class="form-check form-check-custom form-check-solid form-check-sm">
                        <input 
                        class="form-check-input" 
                        type="checkbox"
                        :disabled="!hasData"
                        :checked="initialValueCheck"
                        @change="handleCheckAll($event)"
                        />
                    </div>
                </th>
                <th v-if="requireStatusCol" style="width: 10px;"></th> 
                <th v-for="header in headers" v-bind:key="header">{{ header }}</th>
                <th v-if="requireActions">Acciones</th>
            </tr>
          </thead>
          <tbody v-if="dataRows" :class="{'fw-semibold': activeBodySemibold, 'text-gray-600': activeBodySemibold}">
                <template v-if="loading">
                     <td colspan="100%">
                        <Loading></Loading>
                    </td>
                </template>
                <template v-else-if="!hasData">
                    <td colspan="100%">
                        <NoDataMessage :message="messageNoData"></NoDataMessage>
                    </td>
                </template>
              <slot></slot>
          </tbody>
          
        </table>
    </div>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import Loading from "@/components/shared/Loading.vue";

export default defineComponent({
    name: "app-datatable",
    props: {
        requireSelectMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        requireSelectCheck: {
            type: Boolean,
            required: false,
            default: false
        },
        requireActions: {
            type: Boolean,
            required: false,
            default: false
        },
        headers: {
            type: Array,
            required: false,
            default: () => []
        },
        dataRows: {
            type: Array,
            required: false,
            default: () => []
        },
        requireStatusCol: {
          type: Boolean,
          required: false,
          default: () => false
        },
        initialValueCheck: {
            type: Boolean
            , default: false
        },
        hover: {
            type: Boolean,
            required: false,
            default: false
        },
        bodySemibold: {
            type: Boolean,
            required: false,
            default: false
        },
        hasData: {
            type: Boolean
            , default: true
        },
        loading: {
            type: Boolean
            , default: false
        },
         messageNoData: {
            type: String
            , default: 'No Data'
        }
    },
    components:{
        NoDataMessage
        , Loading
    },
    emits: ['click-check-all'],
    setup(props, context){
        const { hover, bodySemibold, requireSelectMultiple } = toRefs(props);

        const activeHover = computed(() => hover.value);
        const activeBodySemibold = computed(() => bodySemibold.value)

        const handleCheckAll = (event) => {
            //
            if(requireSelectMultiple.value){
                context.emit("click-check-all", event.target.checked)
            }
        }

        return {
            activeHover
            , activeBodySemibold
            , handleCheckAll
        }
    }
});
</script>