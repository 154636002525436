import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6" }
const _hoisted_3 = { class: "svg-icon svg-icon-2tx svg-icon-primary me-4" }
const _hoisted_4 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_5 = { class: "fw-bold" }
const _hoisted_6 = { class: "fs-6 text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.message), 1)
        ])
      ])
    ])
  ]))
}